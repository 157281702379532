import { store } from '../index';

export async function handleResponse(response) {
  if (response.ok) {
    if (response.status === 201) {
      return { message: 'created' };
    }
    if (response.status === 204) {
      return { message: 'success' };
    }
    return response.json();
  }

  if (response.status === 400) {
    // So, a server-side validation error occurred.
    // Server side validation returns a string error message, so parse as text instead of json.
    const message = await response.text();
    // const [error] = Object.values(message);
    const e = new Error(message);
    e.name = 'Bad Request';
    throw e;
  } else if (response.status === 401) {
    const message = await response.json();
    if (message.detail === 'Given token not valid for any token type') {
      store.dispatch({ type: 'INVALID_TOKEN' });
      // throw new Error('Network response was not ok.');
      return;
      // TODO loook at redirect to login here 'redirect()'
    } else if (message.detail === 'Token is invalid or expired') {
      store.dispatch({ type: 'REFRESH_EXPIRED' });
      return;
      // TODO Dispract Refresh error => Logout
    } else {
      const [error] = Object.values(message);
      const e = new Error(error);
      e.name = 'Unauthorized';
      throw e;
    }
  } else if (response.status === 403) {
    const message = await response.json();
    const [error] = Object.values(message);
    const e = new Error(error);
    e.name = 'Forbidden';
    throw e;
  } else if (response.status === 404) {
    const message = await response.json();
    const [error] = Object.values(message);
    const e = new Error(error);
    e.name = 'Not Found';
    throw e;
    // TODO build redirect to 404 page
  }
  throw new Error('Network response was not ok.');
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  // eslint-disable-next-line no-console
  console.error('API call failed. ', error.toString());
  throw error;
}
