import { Content, Footer, Header } from 'components/Layout';
import React from 'react';
import { bindActionCreators } from 'redux';
import * as authActions from '../../redux/actions/authActions';
import { connect } from 'react-redux';
import { Redirect } from 'react-router';
import * as userActions from '../../redux/actions/userActions';
import Notifications, {
  error,
  warning,
  removeAll,
} from 'react-notification-system-redux';

class MainLayout extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      isOpenUserCardPopover: false,
    };
    this.events = [
      'load',
      'mousemove',
      'mousedown',
      'click',
      'scroll',
      'keypress',
    ];

    this.warn = this.warn.bind(this);
    this.resetTimeout = this.resetTimeout.bind(this);
    for (let i in this.events) {
      window.addEventListener(this.events[i], this.resetTimeout);
    }
    this.setTimeout();
  }

  clearTimeout() {
    if (this.warnTimeout) clearTimeout(this.warnTimeout);
    if (this.logoutTimeout) clearTimeout(this.logoutTimeout);
  }

  setTimeout() {
    this.warnTimeout = setTimeout(this.warn, 15 * 60 * 1000);
    this.logoutTimeout = setTimeout(this.handle_logout, 20 * 60 * 1000);
    // if (!this.tokenRefreshTimeout) {
    //   this.tokenRefreshTimeout = setTimeout(this.token_refresh, 10 * 60 * 1000);
    // }
  }

  resetTimeout() {
    this.clearTimeout();
    this.setTimeout();
  }

  warn() {
    const message = {
      title: 'Attention',
      message:
        'You will be automatically logged out in 5 minutes due to inactivity',
      autoDismiss: 0,
      position: 'tc',
    };
    this.props.actions.warning(message);
  }

  destroy() {
    this.clearTimeout();

    for (let i in this.events) {
      window.removeEventListener(this.events[i], this.resetTimeout);
    }
  }

  componentDidMount() {
    if (this.props.auth.logged_in) {
      this.props.actions.loadUser().catch(errors => {
        const message = {
          title: errors.name,
          message: errors.message,
          autoDismiss: 0,
          position: 'tc',
        };
        this.props.actions.error(message);
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Typical usage (don't forget to compare props):
    if (this.props.auth.logged_in !== prevProps.auth.logged_in) {
      this.setState({ redirectToLogin: true });
    }
  }

  componentWillUnmount() {
    this.destroy();
    // this.props.actions.remove();
  }

  static isSidebarOpen() {
    return document
      .querySelector('.cr-sidebar')
      .classList.contains('cr-sidebar--open');
  }

  handle_logout = async () => {
    try {
      await this.props.actions.requestLogout();
    } catch (error) {
      console.log('logout error ', error);
    }
    // this.setState({ redirectToLogin: true });
  };

  // token_refresh = () => {
  //   if (localStorage.getItem('access')) {
  //     this.props.actions
  //       .refreshToken()
  //       .then(clearTimeout(this.tokenRefreshTimeout));
  //   } else {
  //     //Todo make this either a useful async or no asyc
  //     this.handle_logout().then();
  //   }
  // };

  handleContentClick = event => {
    // Closes user card by clicking anything else on the screen
    if (this.state.isOpenUserCardPopover) {
      this.setState({
        isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
      });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };
  // componentWillReceiveProps({ breakpoint }) {
  //   if (breakpoint !== this.props.breakpoint) {
  //     this.checkBreakpoint(breakpoint);
  //   }
  // }
  //
  // componentDidMount() {
  //   this.checkBreakpoint(this.props.breakpoint);

  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }
  //
  //     this.notificationSystem.addNotification({
  //       title: <MdImportantDevices />,
  //       message: 'Welome to Reduction Admin!',
  //       level: 'info',
  //     });
  //   }, 1500);
  //
  //   setTimeout(() => {
  //     if (!this.notificationSystem) {
  //       return;
  //     }
  //
  //     this.notificationSystem.addNotification({
  //       title: <MdLoyalty />,
  //       message:
  //         'Reduction is carefully designed template powered by React and Bootstrap4!',
  //       level: 'info',
  //     });
  //   }, 2500);
  // }

  render() {
    const { children } = this.props;
    return (
      <main className="cr-app bg-light">
        {this.state.redirectToLogin && <Redirect to="/" />}
        <Content fluid onClick={this.handleContentClick}>
          <Header
            handle_logout={this.handle_logout}
            user={this.props.user}
            isOpenUserCardPopover={this.state.isOpenUserCardPopover}
            toggleUserCardPopover={this.toggleUserCardPopover}
          />
          {children}
          <Footer />
        </Content>
        <Notifications notifications={this.props.notifications} />
      </main>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    user: state.user,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      requestLogout: bindActionCreators(authActions.requestLogout, dispatch),
      loadUser: bindActionCreators(userActions.loadUser, dispatch),
      warning: bindActionCreators(warning, dispatch),
      refreshToken: bindActionCreators(authActions.refreshToken, dispatch),
      error: bindActionCreators(error, dispatch),
      remove: bindActionCreators(removeAll, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MainLayout);
