export default {
  trips: [],
  resorts: [],
  auth: {
    authPending: false,
    logged_in: !!localStorage.getItem('access'),
    access: null,
    refresh: null,
    tokenIsValid: null,
    pendingRefreshingToken: null,
  },
  user: {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    id: '',
    avatar_url: '',
  },
  profile: {},
  queue: [],
  apiCallsInProgress: 0,
  billing: [],
  match: {
    "pricematchfiles": [
      {
        "id": null,
        "uploaded_at": '',
        "upload": '',
        "tripmatch": null
      }
    ],
    "id": null,
    "active": false,
    "completed": false,
    "price_to_beat": '',
    "created_at": '',
    "match": false,
    "match_list_price": '',
    "match_discount_price": '',
    "dt_completed": '',
    "savings": '',
    "guid": '',
    "is_paid": false,
    "trip": {
      "id": null,
      "active": false,
      "reservation_number": '',
      "notes": '',
      "checkin": '',
      "checkout": '',
      "adults": null,
      "kids": null,
      "kid_1": null,
      "kid_2": null,
      "kid_3": null,
      "kid_4": null,
      "original_price": '',
      "current_price": '',
      "discount": null,
      "resort": '',
      "roomtype": ''
    },
    "agency": {
      "id": null,
      "name": ''
    },
    "paymentamount": null
  }
};
