import logo200Image from 'assets/img/logo/logo512-trimmy.png';
import PropTypes from 'prop-types';
import React from 'react';
import {
  Button,
  Col,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import { Link } from 'react-router-dom';

class AuthForm extends React.Component {
  state = {
    email: '',
    password: '',
    checkbox: '',
  };

  get isLogin() {
    return this.props.authState === STATE_LOGIN;
  }

  get isSignup() {
    return this.props.authState === STATE_SIGNUP;
  }

  changeAuthState = authState => event => {
    event.preventDefault();

    this.props.onChangeAuthState(authState);
  };

  // handleSubmit = event => {
  //   event.preventDefault();
  //   this.props.onLogoClick
  // };

  renderButtonText() {
    const { buttonText } = this.props;

    if (!buttonText && this.isLogin) {
      return 'Login';
    }

    if (!buttonText && this.isSignup) {
      return 'Signup';
    }

    return buttonText;
  }

  handle_change = e => {
    const { name, value } = e.target;
    if (name === 'email') {
      this.setState(prevstate => ({
        ...prevstate,
        username: value.toLowerCase(),
        [name]: value,
      }));
    } else if (name === 'checkbox') {
      this.setState(prevstate => {
        if (prevstate.checkbox === 'on') {
          return {
            ...prevstate,
            [name]: '',
          };
        } else {
          return {
            ...prevstate,
            [name]: 'on',
          };
        }
      });
    } else {
      this.setState(prevstate => ({
        ...prevstate,
        [name]: value,
      }));
    }
  };

  render() {
    const {
      showLogo,
      usernameLabel,
      firstNameLabel,
      lastNameLabel,
      usernameInputProps,
      passwordLabel,
      passwordInputProps,
      firstNameInputProps,
      lastNameInputProps,
      confirmPasswordLabel,
      confirmPasswordInputProps,
      children,
      onLogoClick,
      errors = {},
    } = this.props;

    return (
      <Form onSubmit={e => this.props.handle_login(e, this.state)}>
        {showLogo && (
          <div className="text-center pb-4">
            <img
              src={logo200Image}
              style={{ width: '40%', cursor: 'pointer' }}
              alt="logo"
              onClick={onLogoClick}
            />
          </div>
        )}
        {this.isSignup && (
          <Row>
            <Col>
              <FormGroup>
                <Label for={firstNameLabel}>{firstNameLabel}</Label>
                {errors.first_name ? (
                  <>
                    <Input
                      name="first_name"
                      {...firstNameInputProps}
                      onChange={this.handle_change}
                      invalid
                    />
                    <FormFeedback>{errors.first_name}</FormFeedback>
                  </>
                ) : (
                  <Input
                    name="first_name"
                    {...firstNameInputProps}
                    onChange={this.handle_change}
                  />
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for={lastNameLabel}>{lastNameLabel}</Label>
                {errors.last_name ? (
                  <>
                    <Input
                      name="last_name"
                      {...lastNameInputProps}
                      onChange={this.handle_change}
                      invalid
                    />
                    <FormFeedback>{errors.last_name}</FormFeedback>
                  </>
                ) : (
                  <Input
                    name="last_name"
                    {...lastNameInputProps}
                    onChange={this.handle_change}
                  />
                )}
              </FormGroup>
            </Col>
          </Row>
        )}
        <FormGroup>
          <Label for={usernameLabel}>{usernameLabel}</Label>
          {errors.email ? (
            <>
              <Input
                name="email"
                {...usernameInputProps}
                onChange={this.handle_change}
                invalid
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </>
          ) : (
            <Input
              name="email"
              {...usernameInputProps}
              onChange={this.handle_change}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label for={passwordLabel}>{passwordLabel}</Label>
          {errors.password ? (
            <>
              <Input
                {...passwordInputProps}
                name="password"
                onChange={this.handle_change}
                invalid
              />
              <FormFeedback>{errors.password}</FormFeedback>
            </>
          ) : (
            <Input
              {...passwordInputProps}
              name="password"
              onChange={this.handle_change}
            />
          )}
        </FormGroup>
        {this.isSignup && (
          <FormGroup>
            <Label for={confirmPasswordLabel}>{confirmPasswordLabel}</Label>
            {errors.passwordMatch ? (
              <>
                <Input
                  name="confirmPassword"
                  onChange={this.handle_change}
                  {...confirmPasswordInputProps}
                  invalid
                />
                <FormFeedback>{errors.passwordMatch}</FormFeedback>
              </>
            ) : (
              <Input
                name="confirmPassword"
                onChange={this.handle_change}
                {...confirmPasswordInputProps}
              />
            )}
          </FormGroup>
        )}
        {this.isLogin && (
          <FormGroup>
            <Link to="password_reset">Forgot Password?</Link>
          </FormGroup>
        )}
        {this.isSignup &&
          (errors.checkbox ? (
            <FormGroup check>
              <Label check>
                <Input
                  name="checkbox"
                  type="checkbox"
                  checked={this.state.checkbox}
                  onChange={this.handle_change}
                  invalid
                />
                Agree the <Link to="/terms">Terms and policy</Link>
                <FormFeedback>{errors.checkbox}</FormFeedback>
              </Label>
            </FormGroup>
          ) : (
            <FormGroup check>
              <Label check>
                <Input
                  name="checkbox"
                  type="checkbox"
                  checked={this.state.checkbox}
                  onChange={this.handle_change}
                />
                Agree the <Link to="/terms">Terms and policy</Link>
              </Label>
            </FormGroup>
          ))}
        <hr />
        <Button
          size="lg"
          className="bg-gradient-theme-left border-0"
          block
          // onClick={this.props.handle_Submit}
          type="submit"
        >
          {this.renderButtonText()}
        </Button>
        <div className="text-center pt-1">
          <h6>or</h6>
          <h6>
            {this.isSignup ? (
              <a href="#login" onClick={this.changeAuthState(STATE_LOGIN)}>
                Login
              </a>
            ) : (
              <a href="#signup" onClick={this.changeAuthState(STATE_SIGNUP)}>
                Signup
              </a>
            )}
          </h6>
        </div>
        <hr />
        {children}
      </Form>
    );
  }
}

export const STATE_LOGIN = 'LOGIN';
export const STATE_SIGNUP = 'SIGNUP';

AuthForm.propTypes = {
  authState: PropTypes.oneOf([STATE_LOGIN, STATE_SIGNUP]).isRequired,
  showLogo: PropTypes.bool,
  usernameLabel: PropTypes.string,
  usernameInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object,
  onLogoClick: PropTypes.func,
};

AuthForm.defaultProps = {
  authState: 'LOGIN',
  showLogo: true,
  usernameLabel: 'Email',
  firstNameLabel: 'First Name',
  lastNameLabel: 'Last Name',
  usernameInputProps: {
    type: 'text',
    placeholder: 'your@email.com',
  },
  firstNameInputProps: {
    type: 'text',
    placeholder: 'First',
  },
  lastNameInputProps: {
    type: 'text',
    placeholder: 'Last',
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'your password',
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'confirm your password',
  },
  onLogoClick: () => {},
};

export default AuthForm;
