import { combineReducers } from 'redux';

import trips from './tripReducer';
import resorts from './resortReducer';
import auth from './authReducer';
import user from './userReducer';
import queue from './queueReducer';
import profile from './profileReducer';
import apiCallsInProgress from './apiStatusReducer';
import billing from './billingReducer';
import match from './matchReducer'

import { reducer as notifications } from 'react-notification-system-redux';

import { LOGOUT_OPTIMISTIC } from '../actions/actionTypes';

const appReducer = combineReducers({
  trips,
  resorts,
  auth,
  user,
  queue,
  profile,
  apiCallsInProgress,
  notifications,
  billing,
  match,
});

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_OPTIMISTIC) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
