import React from 'react';
import { GoogleLogin } from 'react-google-login';

const GoogleSignInForm = props => {
  const responseGoogle = response => {
    const userSocial = {
      provider: 'google-oauth2',
      access_token: response.accessToken,
    };

    props.handle_socialAuth(userSocial);
  };

  const responseFailGoogle = response => {
    if (response.error !== 'popup_closed_by_user') {
      console.error(response);
      const message = {
        title: 'Unhandled response from Googe',
        message: 'Please try again later or contact support for more options.',
        autoDismiss: 10,
      };
      props.errors_notification(message);
    }
  };

  function changeHover(e) {
    e.target.className =
      'bg-google border-0 btn btn-lg btn-block text-white mb-2';
  }
  function changeHoverBack(e) {
    e.target.className =
      'bg-light border-0 btn btn-lg btn-block text-dark mb-2';
  }

  return (
    <GoogleLogin
      clientId={process.env.REACT_APP_GOOGLE_KEY}
      render={renderProps => (
        <button
          onClick={renderProps.onClick}
          disabled={renderProps.disabled}
          onMouseEnter={changeHover}
          onMouseLeave={changeHoverBack}
          className="bg-light border-0 btn btn-lg btn-block text-dark mb-2"
        >
          Login with Google
        </button>
      )}
      onSuccess={responseGoogle}
      onFailure={responseFailGoogle}
      cookiePolicy={'single_host_origin'}
      autoLoad={false}
    />
  );
};

export default GoogleSignInForm;
