import * as types from './actionTypes';
import * as authApi from '../../api/authApi';
import { apiCallError, beginApiCall } from './apiStatusActions';

export function loginSuccess(tokens) {
  return { type: types.LOGIN_SUCCESS, tokens };
}

export function createUserSuccess() {
  return { type: types.CREATE_USER_SUCCESS };
}

export function createUserPasswordSuccess() {
  return { type: types.CREATE_USER_PASSWORD_SUCCESS };
}

export function activateUserSuccess() {
  return { type: types.ACTIVATE_USER_SUCCESS };
}

export function logoutOptimistic() {
  return { type: types.LOGOUT_OPTIMISTIC };
}

export function updatePasswordSuccess() {
  return { type: types.UPDATE_PASSWORD_SUCCESS };
}

export function refreshTokenSuccess(token) {
  return { type: types.REFRESH_TOKEN_SUCCESS, token };
}
export function resetPasswordInitiatedSuccess() {
  return { type: types.RESET_PASSWORD_INITIATED_SUCCESS };
}

export function resetPasswordActivatedSuccess() {
  return { type: types.RESET_PASSWORD_ACTIVATED_SUCCESS };
}

export function disconnectSocialSuccess() {
  return { type: types.DISCONNECT_SOCIAL_SUCCESS };
}

export function socialAssocSuccess() {
  return { type: types.CONNECT_SOCIAL_SUCCESS };
}

export function requestLogin(user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .requestLogin(user)
      .then(resp => {
        localStorage.setItem('access', resp.access);
        localStorage.setItem('refresh', resp.refresh);
        dispatch(loginSuccess(resp));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function requestLoginSocial(user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .requestLoginSocial(user)
      .then(resp => {
        localStorage.setItem('access', resp.access);
        localStorage.setItem('refresh', resp.refresh);
        dispatch(loginSuccess(resp));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function requestAccessSocial(creds) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .requestAccessSocial(creds)
      .then(() => {
        dispatch(socialAssocSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function createUser(user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .createUser(user)
      .then(resp => {
        dispatch(createUserSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function activateUser(creds) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .activateUser(creds)
      .then(resp => {
        dispatch(activateUserSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function requestLogout() {
  return function (dispatch) {
    localStorage.clear();
    dispatch(logoutOptimistic());
    return { message: 'Success' };
  };
}

export function updatePassword(password, user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .changePassword(password, user)
      .then(resp => {
        dispatch(updatePasswordSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function refreshToken() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .refreshToken()
      .then(resp => {
        localStorage.setItem('access', resp.access);
        dispatch(refreshTokenSuccess(resp));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        dispatch(requestLogout());
        throw error;
      });
  };
}

export function resetPassword(email) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .resetPassword(email)
      .then(resp => {
        dispatch(resetPasswordInitiatedSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function resetPasswordActivation(creds) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .resetPasswordActivation(creds)
      .then(resp => {
        dispatch(resetPasswordActivatedSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function disconnectSocial(backend) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .disconnectSocial(backend)
      .then(() => {
        dispatch(disconnectSocialSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function createPassword(user) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .createPassword(user)
      .then(() => {
        dispatch(createUserPasswordSuccess());
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
