import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import React from 'react';
import logo200Image from 'assets/img/logo/logo512-trimmy.png';

function RegisterUserPage(props) {
  const handleLogoClick = () => {
    props.history.push('/');
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={8} lg={6}>
        <Card className=" text-center">
          <CardHeader className="bg-light">
            <CardTitle className="h2">Email Activation Sent</CardTitle>
          </CardHeader>
          <CardBody className="">
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: '40%', cursor: 'pointer' }}
                alt="logo"
                onClick={handleLogoClick}
              />
            </div>
            <CardText className="">
              Check your email for the activation link in order to get started.
            </CardText>
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
}

export default RegisterUserPage;
