import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function billingReducer(state = initialState.billing, action) {
  switch (action.type) {
    case types.LOAD_BILLING_SUCCESS:
      return action.logs;
    default:
      return state;
  }
}
