import React from 'react';
import FacebookLogin from 'react-facebook-login';

const FacebookSignInForm = props => {
  // const [userSocial, setUserSocial] = useState({});
  const responseFacebook = response => {
    // setUserSocial({
    //   provider: response.graphDomain,
    //   access_token: response.accessToken,
    // });
    const userSocial = {
      provider: response.graphDomain,
      access_token: response.accessToken,
    };
    props.handle_socialAuth(userSocial);
  };

  const responseFailFacebook = response => {
    console.error(response);
    const message = {
      title: 'Unhandled response from Facebook',
      message: 'Please try again later or contact support for more options.',
      autoDismiss: 10,
    };
    props.errors_notification(message);
  };
  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_KEY}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      textButton="Login with Facebook"
      cssClass="bg-facebook border-0 btn btn-lg btn-block text-white mb-2"
      onFailure={responseFailFacebook}
      disableMobileRedirect={true}
    />
  );
};

export default FacebookSignInForm;
