import * as types from './actionTypes';
import * as userApi from '../../api/userApi';

import { apiCallError, beginApiCall } from './apiStatusActions';

export function loadUserSuccess(user) {
  return { type: types.LOAD_USER_SUCCESS, user };
}

export function loadSocialsSuccess(socials) {
  return { type: types.LOAD_SOCIALS_SUCCESS, socials };
}

export function loadUser() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getUser()
      .then(user => {
        dispatch(loadUserSuccess(user));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function loadSocials() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return userApi
      .getSocials()
      .then(socials => {
        dispatch(loadSocialsSuccess(socials));
      })
      .catch(error => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
