import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';

import { Provider as ReduxProvider } from 'react-redux';
import { configureStore } from '@reduxjs/toolkit';
import initialState from './redux/reducers/initialState';
import rootReducer from './redux/reducers/index';
import { jwt } from './redux/middleware';

export const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware => getDefaultMiddleware().prepend(jwt),
});

ReactDOM.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>,
  document.getElementById('root'),
);
