import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function authReducer(state = initialState.auth, action) {
  switch (action.type) {
    case types.LOGIN_SUCCESS:
      return {
        ...state,
        authPending: false,
        logged_in: !!localStorage.getItem('access'),
        access: action.tokens.access,
        refresh: action.tokens.refresh,
      };
    case types.LOGOUT_OPTIMISTIC:
      return {
        ...state,
        logged_in: false,
        access: null,
        refresh: null,
      };
    case types.INVALID_TOKEN:
      return {
        ...state,
        tokenIsValid: false,
      };
    case types.REFRESHING_TOKEN:
      return {
        ...state,
        pendingRefreshingToken: true,
        tokenIsValid: false,
      };
    case types.TOKEN_REFRESHED:
      return {
        ...state,
        pendingRefreshingToken: null,
        tokenIsValid: true,
      };
    case types.REFRESH_TOKEN_SUCCESS_MIDDLEWARE:
      return {
        ...state,
        access: action.access,
      };
    case types.REFRESH_TOKEN_SUCCESS:
      return {
        ...state,
        access: action.token.access,
        pendingRefreshingToken: null,
        tokenIsValid: true,
      };
    default:
      return state;
  }
}
