import { handleError, handleResponse } from './apiUtils';
//TODO Config baseURL for actual links vs local dev
const baseUrl = process.env.REACT_APP_SOURCE_URL;

export function getUser() {
  return fetch(baseUrl + 'api/current_user/', {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getSocials() {
  return fetch(baseUrl + 'api/socials/', {
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
    },
  })
    .then(handleResponse)
    .catch(handleError);
}
