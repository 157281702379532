import { STATE_LOGIN, STATE_SIGNUP } from 'components/AuthForm';
import { EmptyLayout, LayoutRoute, MainLayout } from 'components/Layout';
import PageSpinner from 'components/PageSpinner';
import AuthPage from 'pages/AuthPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage';
import React from 'react';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import './styles/reduction.scss';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as userActions from './redux/actions/userActions';
//  TODO do these need to be lazy
import RegisterUserPage from './pages/RegisterUser';
import ConfirmUserPage from './pages/ConfirmUserPage';
import PrivateRoute from './components/Layout/PrivateRoute';

const TripsPage = React.lazy(() => import('pages/TripsPage'));
const ManageTripsPage = React.lazy(() => import('pages/ManageTripsPage'));
const TripPricingPage = React.lazy(() => import('pages/TripPricingPage'));
const TripDetailsPage = React.lazy(() => import('pages/TripDetailsPage'));
// const BillingPage = React.lazy(() => import('pages/BillingPage'));
const PasswordPage = React.lazy(() => import('pages/PasswordPage'));
const SocialAccountsPage = React.lazy(() => import('pages/SocialAccountsPage'));
const TermsPage = React.lazy(() => import('pages/TermsPage'));
const Error404Page = React.lazy(() => import('pages/404Page'));
const LogoutPage = React.lazy(() => import('pages/LogoutPage'));
const DonationsPage = React.lazy(() => import('pages/DonationsPage'));

class App extends React.Component {
  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     logged_in: localStorage.getItem('token') ? true : false,
  //     username: '',
  //     first_name: '',
  //     last_name: '',
  //     email: '',
  //   };
  // }

  // componentDidMount() {
  //   console.log('mount', this.props);
  //   if (this.props.user.logged_in) {
  //     this.props.actions.loadUser().catch(error => {
  //       alert('loadUser failed' + error);
  //     });
  //   }
  // }

  // handle_logout = () => {
  //   localStorage.removeItem('token');
  //   this.setState({ logged_in: false, username: '' });
  // };

  // componentDidUpdate(prevProps) {
  //   if (prevProps.auth !== this.props.auth) {
  //     if (this.props.auth.logged_in) {
  //       this.props.actions.loadUser().catch(error => {
  //         alert('Loading User failed' + error);
  //       });
  //     }
  //   }
  // }

  render() {
    console.log('Welcome to PriceMatchIt');
    return (
      <BrowserRouter>
        {/*<GAListener>*/}
        <Switch>
          <LayoutRoute
            exact
            path="/"
            layout={EmptyLayout}
            component={props => <AuthPage {...props} authState={STATE_LOGIN} />}
          />
          <LayoutRoute
            exact
            path="/signup"
            layout={EmptyLayout}
            component={props => (
              <AuthPage {...props} authState={STATE_SIGNUP} />
            )}
          />
          <LayoutRoute
            exact
            path="/password_reset/:uid/:token"
            layout={EmptyLayout}
            component={props => <ForgotPasswordPage {...props} />}
          />
          <LayoutRoute
            exact
            path="/password_reset"
            layout={EmptyLayout}
            component={props => <ForgotPasswordPage {...props} />}
          />
          <LayoutRoute
            exact
            path="/register_user/:uid/:token"
            layout={EmptyLayout}
            component={props => <ConfirmUserPage {...props} />}
          />
          <LayoutRoute
            exact
            path="/register_user"
            layout={EmptyLayout}
            component={props => <RegisterUserPage {...props} />}
          />
          <MainLayout breakpoint={this.props.breakpoint}>
            <React.Suspense fallback={<PageSpinner />}>
              <Switch>
                <PrivateRoute
                  exact
                  path="/trips"
                  component={props => <TripsPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/trips/:guid/pricing"
                  component={props => <TripPricingPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/trips/:guid/success"
                  component={props => <TripDetailsPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/trip/:slug"
                  component={props => <ManageTripsPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/trip"
                  component={props => <ManageTripsPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/donate"
                  component={props => <DonationsPage {...props} />}
                />
                {/*<PrivateRoute*/}
                {/*  exact*/}
                {/*  path="/billing"*/}
                {/*  component={props => <BillingPage {...props} />}*/}
                {/*/>*/}
                <PrivateRoute
                  exact
                  path="/settings/password"
                  component={props => <PasswordPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/settings/account"
                  component={props => <SocialAccountsPage {...props} />}
                />
                <PrivateRoute
                  exact
                  path="/logout"
                  component={props => <LogoutPage {...props} />}
                />
                <Route exact path="/terms" component={TermsPage} />
                <Route>
                  <Error404Page />
                </Route>
              </Switch>
            </React.Suspense>
          </MainLayout>
        </Switch>
        {/*</GAListener>*/}
      </BrowserRouter>
    );
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }

  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      loadUser: bindActionCreators(userActions.loadUser, dispatch),
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(componentQueries(query)(App));
