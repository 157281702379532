import * as types from '../actions/actionTypes';
import initialState from './initialState';
//TODO look at how we handle trips, make state an array, search for particular item and update
export default function matchReducer(state = initialState.match, action) {
  switch (action.type) {
    case types.LOAD_MATCH_PRICING_SUCCESS:
      return {...state, ...action.match }
    case types.LOAD_FREEMATCH_SUCCESS:
      return action.match
    default:
      return state;
  }
}
