import React from 'react';

import { Nav, Navbar, NavItem } from 'reactstrap';
import { FaFacebook, FaInstagram, FaTwitter } from 'react-icons/all';
import bn from '../../utils/bemnames';
import { Link } from 'react-router-dom';

const bem = bn.create('footer');

const Footer = () => {
  const today = new Date();
  const year = today.getFullYear();

  return (
    <Navbar>
      <Nav navbar>
        <NavItem>
          © 2018-{year}. PriceMatchIt, LLC. All Rights Reserved.{' '}
          <Link to="/terms">Terms</Link>
        </NavItem>
      </Nav>

      <Nav navbar className={bem.e('nav-right')}>
        <NavItem className="d-inline-flex">
          <a
            href="https://www.facebook.com/pricematchit"
            target="_blank"
            className="text-decoration-none text-reset"
            rel="noopener noreferrer"
          >
            <FaFacebook className="mx-2" />
          </a>
          <a
            href="https://www.twitter.com/pricematchit"
            target="_blank"
            className="text-decoration-none text-reset"
            rel="noopener noreferrer"
          >
            <FaTwitter className="mx-2" />
          </a>
          <a
            href="https://www.instagram.com/pricematchit"
            target="_blank"
            className="text-decoration-none text-reset"
            rel="noopener noreferrer"
          >
            <FaInstagram className="mx-2" />
          </a>
        </NavItem>
      </Nav>
    </Navbar>
  );
};

export default Footer;
