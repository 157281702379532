import AuthForm, { STATE_LOGIN } from 'components/AuthForm';
import React from 'react';
import { Card, Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as authActions from '../redux/actions/authActions';
import Notifications, { error } from 'react-notification-system-redux';
import { connect } from 'react-redux';
import FacebookSignInForm from '../components/FacebookLogin';
import GoogleSignInForm from '../components/GoogleLogin';

class AuthPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
    };
  }

  handleAuthState = authState => {
    if (authState === STATE_LOGIN) {
      this.props.history.push('/');
    } else {
      this.props.history.push('/signup');
    }
  };

  handleLogoClick = () => {
    this.props.history.push('/');
  };

  formIsValid = creds => {
    const { username, email, password, checkbox } = creds;
    const errors = {};

    if (!username) errors.username = 'Email is required';
    if (!email) errors.email = 'Email is required';
    if (!password) errors.password = 'Password is required';

    if (this.props.authState === 'SIGNUP') {
      const { first_name, last_name, confirmPassword } = creds;
      if (!first_name) errors.first_name = 'First Name is required';
      if (!last_name) errors.last_name = 'Last Name is required';
      if (checkbox !== 'on') errors.checkbox = 'Please accept the terms';
      if (password !== confirmPassword)
        errors.passwordMatch = 'Passwords do not match';
    }

    this.setState({ errors });
    return Object.keys(errors).length === 0;
  };

  handle_login = (e, creds) => {
    e.preventDefault();
    let { from } = this.props.location.state || {
      from: { pathname: '/trips' },
    };
    if (!this.formIsValid(creds)) return;
    if (this.props.authState === 'LOGIN') {
      this.props.actions
        .requestLogin(creds)
        .then(() => {
          this.props.history.replace(from);
        })
        .catch(errors => {
          const message = {
            title: errors.name,
            message: errors.message,
            autoDismiss: 0,
            position: 'tc',
          };
          this.props.actions.error(message);
        });
    } else {
      this.props.actions
        .createUser(creds)
        .then(() => {
          this.props.history.push('/register_user');
        })
        .catch(errors => {
          const message = {
            title: errors.name,
            message: errors.message,
            autoDismiss: 0,
          };
          this.props.actions.error(message);
        });
    }
  };

  handle_socialAuth = creds => {
    let { from } = this.props.location.state || {
      from: { pathname: '/trips' },
    };
    this.props.actions
      .requestLoginSocial(creds)
      .then(() => {
        this.props.history.replace(from);
      })
      .catch(errors => {
        const message = {
          message: `${errors}, please refresh and try again`,
          autoDismiss: 0,
        };
        this.props.actions.error(message);
      });
  };

  render() {
    localStorage.clear();
    return (
      <Row
        style={{
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Col md={6} lg={4}>
          <Card body>
            <AuthForm
              authState={this.props.authState}
              onChangeAuthState={this.handleAuthState}
              onLogoClick={this.handleLogoClick}
              handle_login={this.handle_login}
              errors={this.state.errors}
            >
              <FacebookSignInForm
                handle_socialAuth={this.handle_socialAuth}
                errors_notification={this.props.actions.error}
              />
              <GoogleSignInForm
                handle_socialAuth={this.handle_socialAuth}
                errors_notification={this.props.actions.error}
              />
            </AuthForm>
          </Card>
        </Col>
        <Notifications notifications={this.props.notifications} />
      </Row>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.user,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      requestLogin: bindActionCreators(authActions.requestLogin, dispatch),
      requestLoginSocial: bindActionCreators(
        authActions.requestLoginSocial,
        dispatch,
      ),
      createUser: bindActionCreators(authActions.createUser, dispatch),
      error: bindActionCreators(error, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
