import jwt_decode from 'jwt-decode';

// If true, refresh token is expired or doesn't exist -> Logout
export function checkRefreshToken() {
  if (!localStorage.getItem('refresh')) {
    return true;
  } else {
    const refreshExp = jwt_decode(localStorage.getItem('refresh')).exp;
    return isTokenExpired(refreshExp);
  }
}

// If true, access token is expired -> refresh token
export function checkAccessToken() {
  if (!localStorage.getItem('access')) {
    return true;
  } else {
    const accessExp = jwt_decode(localStorage.getItem('access')).exp;
    return isTokenExpired(accessExp);
  }
}

// checks if token is expired, if it is, return true
export function isTokenExpired(token) {
  const currentTime = new Date();
  currentTime.setMinutes(currentTime.getMinutes() - 2);
  const timeToCompare = Math.floor(currentTime.getTime() / 1000);
  if (token < timeToCompare) {
    return true;
  } else {
    return false;
  }
}
