import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Row,
} from 'reactstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logo200Image from '../assets/img/logo/logo_200.png';
import { bindActionCreators } from 'redux';
import * as authActions from '../redux/actions/authActions';
import Notifications, { error, success } from 'react-notification-system-redux';

function ConfirmUserPage({ actions, history, ...props }) {
  const [userActivationCredentials, setUserActivationCredentials] = useState(
    props.userActivationCredentials,
  );

  useEffect(() => {
    if (!userActivationCredentials) {
      setUserActivationCredentials({
        uid: props.match.params.uid,
        token: props.match.params.token,
      });
    }
    if (userActivationCredentials) {
      actions
        .activateUser(userActivationCredentials)
        .then(() => {
          history.push('/');
          const message = {
            title: 'User successfully Activated',
            message: 'Please log in with your credentials',
            autoDismiss: 15,
            position: 'tr',
          };
          actions.success(message);
        })
        .catch(errors => {
          const message = {
            title: errors.name,
            message: errors.message,
            autoDismiss: 0,
            position: 'tc',
          };
          actions.error(message);
        });
    }
  }, [userActivationCredentials]);

  const handleLogoClick = () => {
    history.push('/');
  };

  return (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={8} lg={6}>
        <Card className=" text-center">
          <CardHeader className="bg-light">
            <CardTitle className="h2">Email Activation Confirmed</CardTitle>
          </CardHeader>
          <CardBody className="">
            <div className="text-center pb-4">
              <img
                src={logo200Image}
                className="rounded"
                style={{ width: 100, cursor: 'pointer' }}
                alt="logo"
                onClick={handleLogoClick}
              />
            </div>
            <CardText className="">
              Navigate to home page and log in to get started
            </CardText>
          </CardBody>
        </Card>
      </Col>
      <Notifications notifications={props.notifications} />
    </Row>
  );
}

function mapStateToProps(state, ownProps) {
  const uid = ownProps.match.params.uid;
  const token = ownProps.match.params.token;
  const credentials = uid && token ? { uid, token } : null;

  return {
    userActivationCredentials: credentials,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      activateUser: bindActionCreators(authActions.activateUser, dispatch),
      error: bindActionCreators(error, dispatch),
      success: bindActionCreators(success, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmUserPage);
