import { handleError, handleResponse } from './apiUtils';
//TODO Config baseURL for actual links vs local dev
const baseUrl = process.env.REACT_APP_SOURCE_URL;

export function requestLogin(user) {
  return fetch(baseUrl + 'api/v2/jwt/create/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function requestLoginSocial(user) {
  return fetch(baseUrl + 'api/social_auth/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function requestAccessSocial(creds) {
  return fetch(baseUrl + 'api/social_assoc/', {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(creds),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createUser(user) {
  return fetch(baseUrl + 'api/v2/users/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function activateUser(user) {
  return fetch(baseUrl + 'api/v2/users/activation/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(user),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function changePassword(passwords, user) {
  return fetch(baseUrl + 'api/settings/password/' + user + '/', {
    method: 'PUT',
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(passwords),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function refreshToken() {
  return fetch(baseUrl + 'api/v2/jwt/refresh/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: localStorage.getItem('refresh') }),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function resetPassword(email) {
  return fetch(baseUrl + 'api/v2/users/reset_password/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(email),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function resetPasswordActivation(creds) {
  return fetch(baseUrl + 'api/v2/users/reset_password_confirm/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(creds),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function disconnectSocial(backend) {
  return fetch(baseUrl + 'api/disconnect-backend/', {
    method: 'POST',
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(backend),
  })
    .then(handleResponse)
    .catch(handleError);
}

export function createPassword(creds) {
  return fetch(baseUrl + 'api/settings/create-password/' + creds.user + '/', {
    method: 'PUT',
    headers: {
      Authorization: `JWT ${localStorage.getItem('access')}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(creds),
  })
    .then(handleResponse)
    .catch(handleError);
}
