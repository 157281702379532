export const CREATE_TRIP_SUCCESS = 'CREATE_TRIP_SUCCESS';
export const CREATE_RESORT = 'CREATE_RESORT';
export const LOAD_TRIPS_SUCCESS = 'LOAD_TRIPS_SUCCESS';
export const UPDATE_TRIP_SUCCESS = 'UPDATE_TRIPS_SUCCESS';
export const LOAD_RESORTS_SUCCESS = 'LOAD_RESORTS_SUCCESS';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS';
export const LOAD_PROFILE_SUCCESS = 'LOAD_PROFILE_SUCCESS';
export const LOAD_FREEMATCH_SUCCESS = 'LOAD_FREEMATCH_SUCCESS';
export const LOAD_QUEUE_SUCCESS = 'LOAD_QUEUE_SUCCESS';
export const LOAD_MATCH_PRICING_SUCCESS = 'LOAD_MATCH_PRICING_SUCCESS'
export const LOAD_SOCIALS_SUCCESS = 'LOAD_SOCIALS_SUCCESS';
export const BEGIN_API_CALL = 'BEGIN_API_CALL';
export const API_CALL_ERROR = 'API_CALL_ERROR';
export const LOAD_BILLING_SUCCESS = 'LOAD_BILLING_SUCCESS';
export const UPDATE_PASSWORD_SUCCESS = 'UPDATE_PASSWORD_SUCCESS';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_SUCCESS_MIDDLEWARE =
  'REFRESH_TOKEN_SUCCESS_MIDDLEWARE';
export const RESET_PASSWORD_INITIATED_SUCCESS =
  'RESET_PASSWORD_INITIATED_SUCCESS';
export const RESET_PASSWORD_ACTIVATED_SUCCESS =
  'RESET_PASSWORD_ACTIVATED_SUCCESS';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const ACTIVATE_USER_SUCCESS = 'ACTIVATE_USER_SUCCESS';
export const DISCONNECT_SOCIAL_SUCCESS = 'DISCONNECT_SOCIAL_SUCCESS';
export const CREATE_USER_PASSWORD_SUCCESS = 'CREATE_USER_PASSWORD_SUCCESS';
export const CONNECT_SOCIAL_SUCCESS = 'CONNECT_SOCIAL_SUCCESS';

export const INVALID_TOKEN = 'INVALID_TOKEN';
export const REFRESHING_TOKEN = 'REFRESHING_TOKEN';
export const TOKEN_REFRESHED = 'TOKEN_REFRESHED';

// By convention, actions that end in "_SUCCESS" are assumed to have been the result of a completed
// API call. But since we're doing an optimistic delete, we're hiding loading state.
// So this action name deliberately omits the "_SUCCESS" suffix.
export const ARCHIVE_TRIP_OPTIMISTIC = 'ARCHIVE_TRIP_OPTIMISTIC';
export const LOGOUT_OPTIMISTIC = 'LOGOUT_OPTIMISTIC';
