import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

const LayoutRoute = ({
  component: Component,
  layout: Layout,
  auth,
  ...rest
}) => (
  <Route
    {...rest}
    render={props => {
      return auth.logged_in === false ? (
        <Layout>
          <Component {...props} />
        </Layout>
      ) : (
        <Redirect to="/trips" />
      );
    }}
  />
);

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default connect(mapStateToProps)(LayoutRoute);
