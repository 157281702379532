import { checkAccessToken, checkRefreshToken } from './middlewareUtils';

const baseUrl = process.env.REACT_APP_SOURCE_URL;

let buffer = [];
let buffer_checker = [];

export const jwt = store => next => action => {
  let theStore = store.getState();
  if (
    action.type === 'REFRESHING_TOKEN' ||
    action.type === 'REFRESH_TOKEN_SUCCESS' ||
    action.type === 'REFRESH_TOKEN_SUCCESS_MIDDLEWARE' ||
    action.type === 'BEGIN_API_CALL' ||
    action.type === 'TOKEN_REFRESHED' ||
    action.type === 'LOGOUT_OPTIMISTIC'
  ) {
    return next(action);
  }

  // if (action.type === 'TOKEN_REFRESHED') {
  //   let queue = buffer.length;
  //   for (let i = 0; i < queue; i++) {
  //     let action_resend = buffer.pop();
  //     buffer_checker.pop();
  //     console.log('dispatching action from buffer', action_resend);
  //     store.dispatch(action_resend);
  //   }
  //   return next(action);
  // }

  if (buffer_checker.includes(action.toString())) {
    return Promise.resolve();
  }

  if (theStore.auth.logged_in) {
    if (checkRefreshToken()) {
      store.dispatch({ type: 'LOGOUT_OPTIMISTIC' });
      localStorage.clear();
      return Promise.resolve();
    } else if (checkAccessToken()) {
      if (!theStore.auth.pendingRefreshingToken) {
        buffer.push(action);
        buffer_checker.push(action.toString());
        store.dispatch({ type: 'REFRESHING_TOKEN' });
        store.dispatch({ type: 'BEGIN_API_CALL' });
        return fetch(baseUrl + 'api/v2/jwt/refresh/', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: localStorage.getItem('refresh') }),
        })
          .then(result => result.json())
          .then(resp => {
            localStorage.setItem('access', resp.access);
            const access = resp.access;
            return store.dispatch({
              type: 'REFRESH_TOKEN_SUCCESS_MIDDLEWARE',
              access,
            });
          })
          .then(() => store.dispatch({ type: 'TOKEN_REFRESHED' }))
          .then(() => {
            let queue = buffer.length;
            for (let i = 0; i < queue; i++) {
              let action_resend = buffer.pop();
              buffer_checker.pop();
              store.dispatch(action_resend);
            }
            return Promise.resolve();
          })
          .catch(() => {
            localStorage.clear();
            return store.dispatch({ type: 'LOGOUT_OPTIMISTIC' });
          });

        // .then(() => {
        // store.dispatch({ type: 'TOKEN_REFRESHED' });
        // });
      } else {
        if (typeof action === 'function') {
          buffer.push(action);
          buffer_checker.push(action.toString());
        }
        return Promise.resolve();
        // function select(state) {
        //   return state.auth.access;
        // }

        // // let currentValue;
        // function handleChange() {
        //   // console.log('currentValue before', currentValue);
        //   // let previousValue = currentValue;
        //   // currentValue = select(store.getState());
        //   // console.log('currentValue after', currentValue);
        //   if (action === 'TOKEN_REFRESHED') {
        //     console.log('changes!!!!');
        //   }
        // }
        //
        // const unsubscribe = store.subscribe(handleChange);
        // unsubscribe();
      }
    } else {
      return next(action);
    }
  } else {
    return next(action);
  }
};
