import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdExitToApp,
  MdHelp,
  MdLockOpen,
  MdNotificationsActive,
  MdPayment,
  MdSettings,
  MdWork,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';

import bn from 'utils/bemnames';
import SourceLink from '../SourceLink';
import logo200Image from '../../assets/img/logo/logo_longer.png';
import { Link } from 'react-router-dom';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>1</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
    };
  }

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  render() {
    const { isNotificationConfirmed } = this.state;
    return (
      <Navbar light expand className={bem.b('bg-white')}>
        <Navbar>
          <SourceLink className="navbar-brand d-flex">
            <img
              src={logo200Image}
              width="auto"
              height="40"
              className="pr-2"
              alt=""
            />
          </SourceLink>
        </Navbar>

        <Nav navbar className={bem.e('nav-right')}>
          {/*<NavItem className="d-inline-flex">*/}
          {/*  <NavLink id="Popover1" className="position-relative">*/}
          {/*    {isNotificationConfirmed ? (*/}
          {/*      <MdNotificationsNone*/}
          {/*        size={25}*/}
          {/*        className="text-secondary can-click"*/}
          {/*        onClick={this.toggleNotificationPopover}*/}
          {/*      />*/}
          {/*    ) : (*/}
          {/*      <MdNotificationsActiveWithBadge*/}
          {/*        size={25}*/}
          {/*        className="text-secondary can-click animated swing infinite"*/}
          {/*        onClick={this.toggleNotificationPopover}*/}
          {/*      />*/}
          {/*    )}*/}
          {/*  </NavLink>*/}
          {/*  <Popover*/}
          {/*    placement="bottom"*/}
          {/*    isOpen={this.state.isOpenNotificationPopover}*/}
          {/*    toggle={this.toggleNotificationPopover}*/}
          {/*    target="Popover1"*/}
          {/*  >*/}
          {/*    <PopoverBody>*/}
          {/*      <Notifications notificationsData={notificationsData} />*/}
          {/*    </PopoverBody>*/}
          {/*  </Popover>*/}
          {/*</NavItem>*/}
          <NavItem className="mr-3 align-self-center">
            <Link to='/donate'>
            <Button color="secondary" size="lg">
              Donate
            </Button>
            </Link>
          </NavItem>

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
                src={this.props.user.avatar_url}
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.props.isOpenUserCardPopover}
              toggle={this.props.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  avatar={this.props.user.avatar_url}
                  avatarSize={65}
                  title={this.props.user.first_name}
                  subtitle={this.props.user.email}
                  // text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    <Link to="/trips" className="text-decoration-none">
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light rounded-0"
                      >
                        <MdWork /> My Trips
                      </ListGroupItem>
                    </Link>
                    {/*<Link to="/billing" className="text-decoration-none">*/}
                    {/*  <ListGroupItem*/}
                    {/*    tag="button"*/}
                    {/*    action*/}
                    {/*    className="border-light rounded-0"*/}
                    {/*  >*/}
                    {/*    <MdPayment /> My Payments/Billing*/}
                    {/*  </ListGroupItem>*/}
                    {/*</Link>*/}
                    <Link
                      to="/settings/account"
                      className="text-decoration-none"
                    >
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light rounded-0"
                      >
                        <MdSettings /> Account Settings
                      </ListGroupItem>
                    </Link>
                    <Link
                      to="/settings/password"
                      className="text-decoration-none"
                    >
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light rounded-0"
                      >
                        <MdLockOpen /> Change Password
                      </ListGroupItem>
                    </Link>
                    <a
                      href="https://pricematchit.freshdesk.com"
                      className="text-decoration-none"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <ListGroupItem
                        tag="button"
                        action
                        className="border-light rounded-0"
                      >
                        <MdHelp /> Help
                      </ListGroupItem>
                    </a>
                    <ListGroupItem
                      tag="button"
                      action
                      className="border-light rounded-0"
                      onClick={e => this.props.handle_logout()}
                    >
                      <MdExitToApp /> Log out
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
