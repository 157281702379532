import { Content } from 'components/Layout';
import beach_background from '../../assets/img/bg/1beach_large.jpg';
import React from 'react';

const EmptyLayout = ({ children, ...restProps }) => (
  <main
    className="cr-app"
    style={{
      backgroundImage: `url(${beach_background})`,
      backgroundSize: 'cover',
    }}
    {...restProps}
  >
    <Content fluid>{children}</Content>
  </main>
);

export default EmptyLayout;
