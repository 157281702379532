import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function userReducer(state = initialState.user, action) {
  switch (action.type) {
    case types.LOAD_USER_SUCCESS:
      return action.user;
    case types.LOAD_SOCIALS_SUCCESS:
      return {
        ...state,
        provider: action.socials.map(social => social.provider),
      };
    case types.CREATE_USER_PASSWORD_SUCCESS:
      return {
        ...state,
        usable_password: true,
      };
    default:
      return state;
  }
}
