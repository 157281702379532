import * as types from '../actions/actionTypes';
import initialState from './initialState';

function actionTypeEndsInSuccess(type) {
  return type.substring(type.length - 8) === '_SUCCESS';
}

function actionTypeEndsInMiddleware(type) {
  return type.substring(type.length - 11) === '_MIDDLEWARE';
}

export default function apiCallStatusReducer(
  state = initialState.apiCallsInProgress,
  action,
) {
  if (action.type === types.BEGIN_API_CALL) {
    return state + 1;
  } else if (
    action.type === types.API_CALL_ERROR ||
    actionTypeEndsInSuccess(action.type) ||
    actionTypeEndsInMiddleware(action.type)
  ) {
    return state - 1;
  }

  return state;
}
