import React, { useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { bindActionCreators } from 'redux';
import * as authActions from '../redux/actions/authActions';
import Notifications, {
  error,
  success,
  warning,
} from 'react-notification-system-redux';
import { connect } from 'react-redux';
import ForgotPasswordForm from '../components/ForgotPasswordForm';
import {
  newPasswordCredentials,
  newPasswordCredentialsActivate,
} from '../utils/initialData';
import { Redirect } from 'react-router-dom';

const baseUrl = process.env.REACT_APP_SOURCE_URL;

function ForgotPasswordPage({ actions, ...props }) {
  const [passwordCredentials, setPasswordCredentials] = useState(
    props.passwordCredentials,
  );
  const [errors, setErrors] = useState({});
  const [redirectLogin, setRedirectLogin] = useState(false);

  function validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  function formIsValid() {
    const errors = {};
    if (!passwordCredentials.uid) {
      const { email } = passwordCredentials;
      if (!email) errors.email = 'Email is required';
      if (!validateEmail(email)) errors.email = 'Enter a valid email ';
    } else {
      const { new_password, re_new_password } = passwordCredentials;
      if (!new_password) errors.new_password = 'Password is required';
      if (new_password !== re_new_password)
        errors.passwordMatch = 'Passwords do not match';
    }
    setErrors(errors);
    return Object.keys(errors).length === 0;
  }

  function handle_save(e) {
    e.preventDefault();
    if (!formIsValid()) return;
    if (!passwordCredentials.uid) {
      actions
        .resetPasswordInitiation(passwordCredentials)
        .then(() => {
          setRedirectLogin(true);
          const message = {
            message: `If that email exists, we've emailed you instructions for setting your password, if an account exists with the email you entered. You should receive them shortly. If you don't receive an email, please make sure you've entered the address you registered with, and check your spam folder.`,
            autoDismiss: 10,
            position: 'tr',
          };
          actions.warning(message);
        })
        .catch(errors => {
          const message = {
            title: errors.name,
            message: errors.message,
            autoDismiss: 0,
            position: 'tc',
          };
          fetch(`${baseUrl}api/v2/users/resend_activation/`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(passwordCredentials)
          }).then(response => response.json()).then(data => console.log(data));
          actions.error(message);
        });
    } else {
      actions
        .resetPasswordActivation(passwordCredentials)
        .then(() => {
          // props.history.push('/')
          setRedirectLogin(true);
          const message = {
            message: 'Password Reset Successful',
            autoDismiss: 15,
            position: 'tr',
          };
          actions.success(message);
        })
        .catch(errors => {
          const message = {
            title: errors.name,
            message: errors.message,
            autoDismiss: 0,
            position: 'tc',
          };
          actions.error(message);
        });
    }
  }

  function handleChange(event) {
    const { name, value } = event.target;
    if (name === 'username') {
      setPasswordCredentials(prevState => ({
        ...prevState,
        [name]: value,
        "email": value.toLowerCase()
      }));
    } else {
      setPasswordCredentials(prevState => ({
        ...prevState,
        [name]: value,
      }));
    }
  }

  return redirectLogin ? (
    <Redirect to="/" />
  ) : (
    <Row
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Col md={6} lg={4}>
        <Card body>
          <h1>Forgot Password?</h1>
          <ForgotPasswordForm
            onChange={handleChange}
            onSubmit={handle_save}
            passwordCredentials={passwordCredentials}
            errors={errors}
            //TODO Add Saving
            // saving
          />
        </Card>
      </Col>
      <Notifications notifications={props.notifications} />
    </Row>
  );
}

function mapStateToProps(state, ownProps) {
  const uid = ownProps.match.params.uid;
  const token = ownProps.match.params.token;
  const credentials =
    uid && token
      ? { ...newPasswordCredentialsActivate, uid, token }
      : newPasswordCredentials;
  return {
    passwordCredentials: credentials,
    notifications: state.notifications,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      resetPasswordInitiation: bindActionCreators(
        authActions.resetPassword,
        dispatch,
      ),
      resetPasswordActivation: bindActionCreators(
        authActions.resetPasswordActivation,
        dispatch,
      ),
      error: bindActionCreators(error, dispatch),
      success: bindActionCreators(success, dispatch),
      warning: bindActionCreators(warning, dispatch),
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordPage);
