import {
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from 'reactstrap';
import React from 'react';
import { Link } from 'react-router-dom';

function ForgotPasswordForm({
  onChange,
  onSubmit,
  passwordCredentials: { username, new_password, re_new_password, uid },
  errors,
  ...props
}) {
  return (
    <Form onSubmit={onSubmit}>
      {!uid ? (
        <>
          {errors.email ? (
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="username"
                value={username || ''}
                placeholder="your@email.com"
                onChange={onChange}
                invalid
              />
              <FormFeedback>{errors.email}</FormFeedback>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>Email</Label>
              <Input
                type="email"
                name="username"
                value={username || ''}
                placeholder="your@email.com"
                onChange={onChange}
              />
            </FormGroup>
          )}
        </>
      ) : (
        <>
          {errors.new_password ? (
            <FormGroup>
              <Label>New Password</Label>
              <Input
                type="password"
                name="new_password"
                value={new_password || ''}
                onChange={onChange}
                invalid
              />
              <FormFeedback>{errors.new_password}</FormFeedback>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>New Password</Label>
              <Input
                type="password"
                name="new_password"
                value={new_password || ''}
                onChange={onChange}
              />
            </FormGroup>
          )}
          {errors.passwordMatch ? (
            <FormGroup>
              <Label>Retype New Password</Label>
              <Input
                type="password"
                name="re_new_password"
                value={re_new_password || ''}
                onChange={onChange}
                invalid
              />
              <FormFeedback>{errors.passwordMatch}</FormFeedback>
            </FormGroup>
          ) : (
            <FormGroup>
              <Label>Retype New Password</Label>
              <Input
                type="password"
                name="re_new_password"
                value={re_new_password || ''}
                onChange={onChange}
              />
            </FormGroup>
          )}
        </>
      )}
      <Row className="justify-content-around align-items-center">
        <Link to="/">Back</Link>
        <Button
          className="m-2"
          // disabled={saving}
        >
          {/*{saving ? 'Email Sent' : 'Reset Password'}*/}
          Reset Password
        </Button>
      </Row>
    </Form>
  );
}

export default ForgotPasswordForm;
