const today = new Date();
const nextWeek = new Date();
const twoWeek = new Date();
const todayFormat = new Date().toISOString().split('T')[0];
nextWeek.setDate(today.getDate() + 7);
const nextWeekFormat = nextWeek.toISOString().split('T')[0];
twoWeek.setDate(today.getDate() + 14);
const twoWeekFormat = twoWeek.toISOString().split('T')[0];

export const newTrip = {
  current_price: '',
  original_price: '',
  original_price_currency: 'USD',
  current_price_currency: 'USD',
  active: true,
  reservation_number: '',
  includeCanada: false,
  notes: '',
  resort: '',
  roomtype: '',
  bedtype: '',
  checkin: nextWeekFormat,
  checkout: twoWeekFormat,
  adults: 2,
  kids: 0,
  kid_1: 0,
  kid_2: 0,
  kid_3: 0,
  kid_4: 0,
  discount: 25,
};

export const newPasswordCredentials = {
  username: '',
};

export const newPasswordCredentialsActivate = {
  new_password: '',
  re_new_password: '',
};
