import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function resortReducer(state = initialState.resorts, action) {
  switch (action.type) {
    case types.CREATE_RESORT:
      return [...state, { ...action.resort }];
    // case types.UPDATE_RESORTS_SUCCESS:
    //   return state.map(course =>
    //     resort.id === action.resort.id ? action.resort : resort,
    //   );
    case types.LOAD_RESORTS_SUCCESS:
      return action.resorts;
    // case types.DELETE_RESORTS_OPTIMISTIC:
    //   return state.filter(resort => resort.id !== action.resort.id);
    default:
      return state;
  }
}
