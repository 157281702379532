import * as types from '../actions/actionTypes';
import initialState from './initialState';

export default function tripReducer(state = initialState.trips, action) {
  switch (action.type) {
    case types.CREATE_TRIP_SUCCESS:
      return action.trips;
    case types.UPDATE_TRIP_SUCCESS:
      return state.map(trip =>
        trip.id === action.trip.id ? action.trip : trip,
      );
    case types.LOAD_TRIPS_SUCCESS:
      return action.trips;
    case types.ARCHIVE_TRIP_OPTIMISTIC:
      return state.map(trip =>
        trip.id === action.trip.id ? action.trip : trip,
      );
    // return state.filter(trip => trip.id !== action.trip.id);
    default:
      return state;
  }
}
